export default [
  {
    title: 'Les Balises',
    route: 'balises',
    icon: 'FileIcon',
    roles: ['ADMIN'],
  },
  {
    title: 'Les Villages',
    route: 'zones',
    icon: 'FileIcon',
    roles: ['ADMIN'],
  },
  {
    title: 'Les Préalertes',
    route: 'prealertes',
    icon: 'FileIcon',
    roles: ['USER', 'ADMIN', 'POINTFOCAL', 'ANPC'],
  },
  {
    title: 'Les Informations',
    route: 'informations',
    icon: 'FileIcon',
    roles: ['USER', 'ADMIN', 'POINTFOCAL', 'ANPC'],
  },
  {
    title: 'Les Utilisateurs',
    route: 'webuser',
    icon: 'FileIcon',
    roles: ['ADMIN'],
  },
  {
    title: 'Les Départements',
    route: 'departements',
    icon: 'FileIcon',
    roles: ['ADMIN'],
  },
  {
    title: 'Les Communes',
    route: 'communes',
    icon: 'FileIcon',
    roles: ['ADMIN'],
  },
  {
    title: 'Les Arrondissements',
    route: 'arrondissements',
    icon: 'FileIcon',
    roles: ['ADMIN'],
  },
  {
    title: 'Gestion des adresses',
    route: 'recevers',
    icon: 'FileIcon',
    roles: ['ADMIN', 'POINTFOCAL'],
  },
  {
    title: 'Les Parametre',
    route: 'settings',
    icon: 'FileIcon',
    roles: ['ADMIN'],
  },
]
