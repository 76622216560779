import parametersMenus from './parametersMenus'

export default [
  {
    title: 'Accueil',
    route: 'home',
    icon: 'HomeIcon',
    roles: ['USER', 'ADMIN', 'POINTFOCAL'],
  },
  ...parametersMenus,
]
